import React from "react";
import theme from "theme";
import { Theme, Image, LinkBox, Box, Text, Link, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"uz"} />
		<Helmet>
			<title>
				Quarkly export
			</title>
			<meta name={"description"} content={"Web site created using quarkly.io"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/63d2db909faa0f0020903b27/images/ban%201.png?v=2023-01-26T19:59:59.944Z"} type={"image/x-icon"} />
			<meta name={"msapplication-TileColor"} content={"#1F212D"} />
		</Helmet>
		<Section min-height="100vh" background="url(https://uploads.quarkly.io/63d2db909faa0f0020903b27/images/Vector.png?v=2023-01-29T20:12:37.467Z) right top/contain no-repeat,url(https://uploads.quarkly.io/63d2db909faa0f0020903b27/images/Vector%20%281%29.png?v=2023-01-29T20:12:04.841Z) 0% 80%/contain no-repeat,--color-dark" padding="0px 0 0px 0">
			<Override slot="SectionContent" max-width="100%" width="100%" align-items="center" />
			<Components.QuarklycommunityKitCollapse
				border-color="--color-primary"
				border-width="2px"
				padding="6px 6px 0px 6px"
				position="absolute"
				top="20px"
				right="20px"
				background="--color-dark"
				sm-top="15px"
				sm-right="15px"
				lg-background="--color-dark"
				z-index="9"
			>
				<Override slot="Button" padding="8px 8px 8px 8px" font="normal 400 12px/1.5 &quot;Montserrat&quot;, sans-serif" letter-spacing="1px">
					Выбрать язык
				</Override>
				<Override slot="Content" display="flex" flex-direction="column" align-items="center" />
				<LinkBox href="/" transition="--opacityOut" hover-opacity=".8">
					<Image
						src="https://uploads.quarkly.io/63d2db909faa0f0020903b27/images/russia%201.svg?v=2023-01-30T11:05:31.359Z"
						display="block"
						height="50px"
						width="50px"
						padding="5px 5px 5px 5px"
					/>
				</LinkBox>
				<LinkBox href="/en" transition="--opacityOut" hover-opacity=".8">
					<Image
						src="https://uploads.quarkly.io/63d2db909faa0f0020903b27/images/russia%202.svg?v=2023-01-31T12:48:06.275Z"
						display="block"
						height="50px"
						width="50px"
						padding="5px 5px 5px 5px"
					/>
				</LinkBox>
				<LinkBox href="/ua" transition="--opacityOut" hover-opacity=".8">
					<Image
						src="https://uploads.quarkly.io/63d2db909faa0f0020903b27/images/russia%203.svg?v=2023-01-31T12:48:23.524Z"
						display="block"
						height="50px"
						width="50px"
						padding="5px 5px 5px 5px"
						margin="0px 0px 5px 0px"
					/>
				</LinkBox>
			</Components.QuarklycommunityKitCollapse>
			<Box
				height="100%"
				display="flex"
				flex-direction="column"
				justify-content="space-around"
				max-width="1280px"
				width="100%"
				lg-padding="0px 24px 0px 24px"
				lg-position="relative"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="none"
					lg-display="flex"
					lg-min-width="0px"
					lg-min-height="0px"
					lg-margin="20px 0px 0px 0px"
					lg-justify-content="space-between"
					lg-width="50%"
				>
					<Image src="https://uploads.quarkly.io/63d2db909faa0f0020903b27/images/Group%2021.svg?v=2023-01-29T20:27:09.720Z" display="block" />
				</Box>
				<Box margin="50px 0px 50px 0px" lg-margin="30px 0px 50px 0px">
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="row"
						align-items="center"
						justify-content="center"
						lg-flex-wrap="wrap"
						lg-justify-content="center"
						lg-text-align="center"
						md-min-width="0px"
						md-min-height="0px"
					>
						<Text
							margin="0px 0px 0px 0px"
							font="--headline1"
							color="--light"
							lg-width="100%"
							lg-font="normal 400 44px/29px &quot;Montserrat&quot;, sans-serif"
							lg-margin="0px 0px 12px 0px"
							md-font="normal 400 24px/29px &quot;Montserrat&quot;, sans-serif"
							md-margin="0px 0px 5px 0px"
							sm-font="normal 400 22px/29px &quot;Montserrat&quot;, sans-serif"
							sm-margin="0px 0px 3px 0px"
						>
							Iltimos, meta-savollar
						</Text>
						<Image
							src="https://uploads.quarkly.io/63d2db909faa0f0020903b27/images/Group%2023%20%281%29.svg?v=2023-01-29T19:56:46.579Z"
							display="block"
							width="95px"
							height="57px"
							margin="0px 10px 15px 10px"
							lg-margin="0px 10px 4px 0px"
							md-width="49px"
							md-height="30px"
						/>
						<Text
							margin="0px 0px 0px 0px"
							font="--headline1"
							color="--light"
							lg-font="normal 400 44px/29px &quot;Montserrat&quot;, sans-serif"
							md-font="normal 400 24px/29px &quot;Montserrat&quot;, sans-serif"
							sm-font="normal 400 22px/29px &quot;Montserrat&quot;, sans-serif"
						>
							bermang
						</Text>
					</Box>
					<Text
						margin="0px 0px 0px 0px"
						text-align="center"
						font="--headline3"
						color="--grey"
						lg-text-align="center"
						lg-margin="17px 0px 0px 0px"
						sm-font="normal 400 14px/24px &quot;Montserrat&quot;, sans-serif"
					>
						Meta-savol-bu boshqa savollarni oldindan nazarda tutadigan savol, masalan:
					</Text>
				</Box>
				<Box margin="0px 0px 50px 0px" lg-display="flex" sm-margin="0px 0px 30px 0px">
					<Box
						min-width="1020px"
						display="flex"
						grid-gap="20px"
						align-items="center"
						justify-content="center"
						lg-flex-wrap="wrap"
						lg-justify-content="flex-start"
						lg-flex-direction="column"
						lg-width="100%"
						lg-min-width="0px"
						lg-display="flex"
						lg-grid-gap="10px"
						lg-margin="0px 0px 30px 0px"
					>
						<Box
							background="linear-gradient(127.11deg, #2A2C3A 0%, #222431 100%)"
							padding="25px 30px 25px 30px"
							border-radius="5px"
							box-shadow="5px 5px 10px rgba(0, 0, 0, 0.05)"
							border-width="2px"
							border-style="solid"
							border-color="--color-greyD1"
							lg-width="100%"
							lg-display="flex"
							sm-padding="20px 15px 20px 15px"
						>
							<Text margin="0px 0px 0px 0px" color="--light" font="--headline3" sm-font="normal 400 14px/24px &quot;Montserrat&quot;, sans-serif">
								Sizdan biror narsa so'rashim mumkinmi?
							</Text>
						</Box>
						<Box
							background="linear-gradient(127.11deg, #2A2C3A 0%, #222431 100%)"
							padding="25px 30px 25px 30px"
							border-radius="5px"
							box-shadow="5px 5px 10px rgba(0, 0, 0, 0.05)"
							border-width="2px"
							border-style="solid"
							border-color="--color-greyD1"
							lg-width="100%"
							lg-display="flex"
							sm-padding="20px 15px 20px 15px"
						>
							<Text margin="0px 0px 0px 0px" color="--light" font="--headline3" sm-font="normal 400 14px/24px &quot;Montserrat&quot;, sans-serif">
								Bu erda N ni tushunadiganlar bormi?
							</Text>
						</Box>
						<Box lg-width="100%" lg-display="flex" lg-grid-gap="10px">
							<Box
								background="linear-gradient(127.11deg, #2A2C3A 0%, #222431 100%)"
								padding="25px 30px 25px 30px"
								border-radius="5px"
								box-shadow="5px 5px 10px rgba(0, 0, 0, 0.05)"
								border-width="2px"
								border-style="solid"
								border-color="--color-greyD1"
								lg-width="100%"
								lg-display="flex"
								sm-padding="20px 15px 20px 15px"
							>
								<Text margin="0px 0px 0px 0px" color="--light" font="--headline3" sm-font="normal 400 14px/24px &quot;Montserrat&quot;, sans-serif">
									Siz shu yerdamisiz?
								</Text>
							</Box>
							<Box
								background="linear-gradient(127.11deg, #2A2C3A 0%, #222431 100%)"
								padding="25px 30px 25px 30px"
								border-radius="5px"
								box-shadow="5px 5px 10px rgba(0, 0, 0, 0.05)"
								border-width="2px"
								border-style="solid"
								border-color="--color-greyD1"
								lg-width="100%"
								lg-display="flex"
								display="none"
								sm-padding="20px 15px 20px 15px"
							>
								<Text margin="0px 0px 0px 0px" color="--light" font="--headline3" sm-font="normal 400 14px/24px &quot;Montserrat&quot;, sans-serif">
									Ку, пингую!
								</Text>
							</Box>
						</Box>
					</Box>
					<Box
						min-width="1020px"
						display="flex"
						grid-gap="20px"
						align-items="center"
						justify-content="center"
						margin="20px 0px 0px 0px"
						lg-justify-content="flex-start"
						lg-flex-direction="column"
						lg-display="none"
					>
						<Box
							background="linear-gradient(127.11deg, #2A2C3A 0%, #222431 100%)"
							padding="25px 30px 25px 30px"
							border-radius="5px"
							box-shadow="5px 5px 10px rgba(0, 0, 0, 0.05)"
							border-width="2px"
							border-style="solid"
							border-color="--color-greyD1"
						>
							<Text margin="0px 0px 0px 0px" color="--light" font="--headline3">
								N kursini kim olgan? Savol bor...
							</Text>
						</Box>
						<Box
							background="linear-gradient(127.11deg, #2A2C3A 0%, #222431 100%)"
							padding="25px 30px 25px 30px"
							border-radius="5px"
							box-shadow="5px 5px 10px rgba(0, 0, 0, 0.05)"
							border-width="2px"
							border-style="solid"
							border-color="--color-greyD1"
						>
							<Text margin="0px 0px 0px 0px" color="--light" font="--headline3">
								Hamkasblar, n haqida savol bor
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					display="grid"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="54px"
					margin="0px 20px 50px 20px"
					lg-grid-template-columns="1fr"
					sm-margin="0px 0px 30px 0px"
					lg-margin="0px 0px 50px 0px"
				>
					<Box
						background="url(https://uploads.quarkly.io/63d2db909faa0f0020903b27/images/Rectangle%206%20%283%29.png?v=2023-01-30T22:00:15.750Z) 0% 0%/cover"
						padding="0px 50px 54px 50px"
						border-radius="30px"
						box-shadow="5px 5px 10px rgba(0, 0, 0, 0.05)"
						md-padding="0px 50px 30px 50px"
						sm-padding="0px 20px 30px 20px"
						sm-border-radius="20px"
					>
						<Box
							align-items="center"
							display="flex"
							justify-content="center"
							margin="0px 0px 34px 0px"
							md-margin="0px 0px 0px 0px"
						>
							<Image
								src="https://uploads.quarkly.io/63d2db909faa0f0020903b27/images/Group%206.png?v=2023-01-29T19:56:04.700Z"
								display="block"
								position="relative"
								top="-35px"
								sm-width="40px"
								sm-height="40px"
								sm-top="-20px"
								srcSet="https://smartuploads.quarkly.io/63d2db909faa0f0020903b27/images/Group%206.png?v=2023-01-29T19%3A56%3A04.700Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/63d2db909faa0f0020903b27/images/Group%206.png?v=2023-01-29T19%3A56%3A04.700Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/63d2db909faa0f0020903b27/images/Group%206.png?v=2023-01-29T19%3A56%3A04.700Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/63d2db909faa0f0020903b27/images/Group%206.png?v=2023-01-29T19%3A56%3A04.700Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/63d2db909faa0f0020903b27/images/Group%206.png?v=2023-01-29T19%3A56%3A04.700Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/63d2db909faa0f0020903b27/images/Group%206.png?v=2023-01-29T19%3A56%3A04.700Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/63d2db909faa0f0020903b27/images/Group%206.png?v=2023-01-29T19%3A56%3A04.700Z&quality=85&w=3200 3200w"
								sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1080px) 100vw,100vw"
							/>
						</Box>
						<Box display="flex" align-items="center" margin="0px 0px 10px 0px">
							<Text margin="0px 8px 0px 18px" color="--light" font="--base" sm-font="normal 400 10px/1.5 &quot;Montserrat&quot;, sans-serif">
								Ivan:
							</Text>
							<Box md-width="100%">
								<Box
									background="linear-gradient(127.11deg, #2A2C3A 0%, #222431 100%)"
									padding="13px 20px 13px 26px"
									border-radius="5px"
									box-shadow="5px 5px 10px rgba(0, 0, 0, 0.05)"
									border-width="2px"
									border-style="solid"
									border-color="--color-greyD1"
									display="flex"
									align-items="center"
									sm-flex-direction="column"
									sm-align-items="flex-start"
									sm-padding="13px 20px 13px 15px"
									sm-position="relative"
									md-width="100%"
									md-margin="0px 0px 0px 7px"
									md-position="relative"
									sm-margin="0px 0px 0px 1px"
								>
									<Text
										margin="0px 52px 0px 0px"
										color="--light"
										font="--base"
										md-font="normal 400 14px/1.5 &quot;Montserrat&quot;, sans-serif"
										sm-font="normal 400 10px/1.5 &quot;Montserrat&quot;, sans-serif"
										sm-margin="0px 0px 0px 0px"
									>
										Salom! Savol bor, siz shu yerdamisiz?
									</Text>
									<Text
										margin="0px 0px 0px 0px"
										color="--grey"
										font="normal 400 10px/1.5 --fontFamily-googleMontserrat"
										sm-font="normal 400 7px/1.5 --fontFamily-googleMontserrat"
										sm-position="absolute"
										sm-right="9px"
										sm-bottom="9px"
										sm-top="auto"
										sm-left="auto"
										md-position="absolute"
										md-right="9px"
										md-bottom="9px"
										md-top="auto"
										md-left="auto"
									>
										12:57
									</Text>
								</Box>
							</Box>
						</Box>
						<Box
							display="flex"
							align-items="center"
							margin="0px 0px 80px 0px"
							md-margin="0px 0px 40px 0px"
							md-align-items="center"
							sm-margin="0px 0px 30px 0px"
							sm-width="100%"
						>
							<Text
								margin="0px 12px 0px 0px"
								color="--light"
								font="--base"
								sm-font="normal 400 10px/1.5 &quot;Montserrat&quot;, sans-serif"
								sm-margin="0px 11px 0px 6px"
							>
								Nikita:
							</Text>
							<Box md-width="100%" sm-width="100%">
								<Box
									background="linear-gradient(127.11deg, #2A2C3A 0%, #222431 100%)"
									padding="13px 20px 13px 26px"
									border-radius="5px"
									box-shadow="5px 5px 10px rgba(0, 0, 0, 0.05)"
									border-width="2px"
									border-style="solid"
									border-color="--color-greyD1"
									display="flex"
									align-items="center"
									sm-flex-direction="column"
									sm-padding="13px 20px 13px 15px"
									sm-position="relative"
									md-width="100%"
									md-margin="0px 0px 0px 8px"
									md-position="relative"
									sm-align-items="flex-start"
									sm-margin="0px 0px 0px 0px"
								>
									<Text
										margin="0px 52px 0px 0px"
										color="--light"
										font="--base"
										md-font="normal 400 14px/1.5 &quot;Montserrat&quot;, sans-serif"
										sm-font="normal 400 10px/1.5 &quot;Montserrat&quot;, sans-serif"
										sm-margin="0px 0px 0px 0px"
									>
										Salom! Eshitaman...
									</Text>
									<Text
										margin="0px 0px 0px 0px"
										color="--grey"
										font="normal 400 10px/1.5 --fontFamily-googleMontserrat"
										sm-font="normal 400 7px/1.5 --fontFamily-googleMontserrat"
										sm-position="absolute"
										sm-right="9px"
										sm-bottom="9px"
										sm-top="auto"
										sm-left="auto"
										md-position="absolute"
										md-right="9px"
										md-bottom="9px"
										md-top="auto"
										md-left="auto"
									>
										12:58
									</Text>
								</Box>
							</Box>
						</Box>
						<Box display="flex" align-items="center" justify-content="center">
							<Image src="https://uploads.quarkly.io/63d2db909faa0f0020903b27/images/fi-rr-time-quarter-to.svg?v=2023-01-29T20:06:26.645Z" display="block" margin="0px 10px 0px 0px" />
							<Text
								margin="0px 0px 0px 0px"
								color="--grey"
								font="--lead"
								text-align="center"
								sm-font="normal 400 8px/1.5 &quot;Montserrat&quot;, sans-serif"
							>
								Nikita, Ivan savolni shakllantirganda javob kutmoqda...
							</Text>
						</Box>
					</Box>
					<Box
						background="url(https://uploads.quarkly.io/63d2db909faa0f0020903b27/images/Rectangle%206%20%284%29.png?v=2023-01-30T22:02:02.771Z) 0% 0%/cover"
						padding="0px 50px 54px 50px"
						border-radius="30px"
						box-shadow="5px 5px 10px rgba(0, 0, 0, 0.05)"
						md-padding="0px 50px 30px 50px"
						sm-padding="0px 20px 30px 20px"
						sm-border-radius="20px"
					>
						<Box
							display="flex"
							align-items="center"
							justify-content="center"
							margin="0px 0px 14px 0px"
							md-margin="0px 0px 0px 0px"
						>
							<Image
								src="https://uploads.quarkly.io/63d2db909faa0f0020903b27/images/plus.svg?v=2023-01-29T20:10:20.964Z"
								display="block"
								position="relative"
								top="-35px"
								sm-width="40px"
								sm-height="40px"
								sm-top="-20px"
							/>
						</Box>
						<Box display="flex" align-items="center" margin="0px 0px 10px 0px">
							<Text margin="0px 18px 0px 18px" color="--light" font="--base" sm-font="normal 400 10px/1.5 &quot;Montserrat&quot;, sans-serif">
								Іван:
							</Text>
							<Box md-width="100%">
								<Box
									background="linear-gradient(127.11deg, #2A2C3A 0%, #222431 100%)"
									padding="13px 20px 13px 26px"
									border-radius="5px"
									box-shadow="5px 5px 10px rgba(0, 0, 0, 0.05)"
									border-width="2px"
									border-style="solid"
									border-color="--color-greyD1"
									display="flex"
									align-items="center"
									sm-flex-direction="column"
									sm-align-items="flex-start"
									sm-padding="13px 20px 13px 15px"
									sm-position="relative"
									md-margin="0px 0px 0px 7px"
									md-position="relative"
									sm-margin="0px 0px 0px 1px"
								>
									<Text
										margin="0px 30px 0px 0px"
										color="--light"
										font="--base"
										md-font="normal 400 14px/1.5 &quot;Montserrat&quot;, sans-serif"
										sm-font="normal 400 10px/1.5 &quot;Montserrat&quot;, sans-serif"
										sm-margin="0px 0px 0px 0px"
									>
										Salom! Men loyiha ustida ishlayapman
										<br />
										N va menga z sohasida maslahat kerak
									</Text>
									<Text
										margin="0px 0px 0px 0px"
										color="--grey"
										font="normal 400 10px/1.5 --fontFamily-googleMontserrat"
										sm-font="normal 400 7px/1.5 --fontFamily-googleMontserrat"
										sm-position="absolute"
										sm-right="9px"
										sm-bottom="9px"
										sm-top="auto"
										sm-left="auto"
										md-position="absolute"
										md-right="9px"
										md-bottom="9px"
										md-top="auto"
										md-left="auto"
									>
										12:57
									</Text>
								</Box>
							</Box>
						</Box>
						<Box
							display="flex"
							align-items="center"
							margin="0px 0px 50px 0px"
							md-margin="0px 0px 40px 0px"
							sm-margin="0px 0px 30px 0px"
						>
							<Text
								margin="0px 8px 0px 0px"
								color="--light"
								font="--base"
								sm-font="normal 400 10px/1.5 &quot;Montserrat&quot;, sans-serif"
								sm-margin="0px 2px 0px 6px"
							>
								Микита:
							</Text>
							<Box md-width="100%">
								<Box
									background="linear-gradient(127.11deg, #2A2C3A 0%, #222431 100%)"
									padding="13px 20px 13px 26px"
									border-radius="5px"
									box-shadow="5px 5px 10px rgba(0, 0, 0, 0.05)"
									border-width="2px"
									border-style="solid"
									border-color="--color-greyD1"
									display="flex"
									align-items="center"
									sm-flex-direction="column"
									sm-align-items="flex-start"
									sm-padding="13px 20px 13px 15px"
									sm-position="relative"
									md-margin="0px 0px 0px 8px"
									md-position="relative"
								>
									<Text
										margin="0px 52px 0px 0px"
										color="--light"
										font="--base"
										md-font="normal 400 14px/1.5 &quot;Montserrat&quot;, sans-serif"
										sm-font="normal 400 10px/1.5 &quot;Montserrat&quot;, sans-serif"
										sm-margin="0px 0px 0px 0px"
										padding="0px 0px 0px 0px"
									>
										Salom! Siz bu erda qilishingiz kerak
										<br />
										quyidagi N
									</Text>
									<Text
										margin="0px 0px 0px 0px"
										color="--grey"
										font="normal 400 10px/1.5 --fontFamily-googleMontserrat"
										sm-font="normal 400 7px/1.5 --fontFamily-googleMontserrat"
										sm-position="absolute"
										sm-right="9px"
										sm-bottom="9px"
										sm-top="auto"
										sm-left="auto"
										md-position="absolute"
										md-right="9px"
										md-bottom="9px"
										md-top="auto"
										md-left="auto"
									>
										12:58
									</Text>
								</Box>
							</Box>
						</Box>
						<Box display="flex" align-items="center" justify-content="center">
							<Image src="https://uploads.quarkly.io/63d2db909faa0f0020903b27/images/fi-rr-time-fast.svg?v=2023-01-29T20:09:20.239Z" display="block" margin="0px 10px 0px 0px" />
							<Text
								margin="0px 0px 0px 0px"
								color="--grey"
								font="--lead"
								text-align="center"
								sm-font="normal 400 8px/1.5 &quot;Montserrat&quot;, sans-serif"
							>
								Ivan savolning mohiyatini shakllantirdi va darhol javob oldi
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
			<Box
				width="100%"
				padding="33px 0px 33px 0px"
				background="--color-darkL1"
				display="flex"
				justify-content="space-around"
				lg-padding="33px 24px 33px 24px"
				sm-display="grid"
				sm-padding="20px 24px 20px 24px"
			>
				<Box
					max-width="1280px"
					width="100%"
					display="grid"
					justify-content="space-between"
					grid-template-columns="repeat(3, 1fr)"
					sm-grid-template-columns="2fr 1fr 1fr"
				>
					<Box>
						<Text
							margin="0px 0px 0px 0px"
							font="--lead"
							color="--darkL2"
							md-font="normal 400 10px/1.5 &quot;Montserrat&quot;, sans-serif"
							sm-font="6px/15px Montserrat, sans-serif"
						>
							Разработано:{" "}
							<Link
								href="https://t.me/newchannel_media"
								text-decoration-line="initial"
								color="--darkL2"
								target="_blank"
								hover-color="--light"
								transition="--opacityOut"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								New Channel Media
							</Link>
						</Text>
					</Box>
					<Box
						display="flex"
						grid-gap="15px"
						align-items="center"
						justify-content="center"
						sm-display="flex"
						sm-grid-gap="5px"
					>
						<Link
							href="/"
							color="--darkL2"
							text-decoration-line="initial"
							font="--lead"
							hover-color="--light"
							transition="--opacityOut"
							md-font="normal 400 10px/1.5 &quot;Montserrat&quot;, sans-serif"
							sm-font="normal 400 6px/1.5 &quot;Montserrat&quot;, sans-serif"
						>
							RU
						</Link>
						<Link
							href="/en"
							color="--darkL2"
							text-decoration-line="initial"
							font="--lead"
							hover-color="--light"
							transition="--opacityOut"
							md-font="normal 400 10px/1.5 &quot;Montserrat&quot;, sans-serif"
							sm-font="normal 400 6px/1.5 &quot;Montserrat&quot;, sans-serif"
						>
							EN
						</Link>
						<Link
							href="/ua"
							color="--darkL2"
							text-decoration-line="initial"
							font="--lead"
							hover-color="--light"
							transition="--opacityOut"
							md-font="normal 400 10px/1.5 &quot;Montserrat&quot;, sans-serif"
							sm-font="normal 400 6px/1.5 &quot;Montserrat&quot;, sans-serif"
						>
							UK
						</Link>
						<Text
							margin="0px 0px 0px 0px"
							font="--lead"
							color="--light"
							md-font="normal 400 10px/1.5 &quot;Montserrat&quot;, sans-serif"
							sm-font="normal 400 6px/1.5 &quot;Montserrat&quot;, sans-serif"
						>
							UZ
						</Text>
					</Box>
					<Box text-align="right" lg-align-items="center" lg-display="flex" lg-justify-content="flex-end">
						<Text
							margin="0px 0px 0px 0px"
							font="--lead"
							color="--darkL2"
							md-font="normal 400 10px/1.5 &quot;Montserrat&quot;, sans-serif"
							sm-font="normal 400 6px/1.5 &quot;Montserrat&quot;, sans-serif"
						>
							© 2023
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.EmbedHTML />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"63d2db909faa0f0020903b25"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
			<meta
				content={"a45ba4545ec933c4"}
				charset={""}
				name={"yandex-verification"}
				place={"endOfHead"}
				rawKey={"63d7c5b1abe735836825f133"}
			/>
			<script place={"endOfBody"} rawKey={"63d90c352be5f8d96f0bcc77"}>
				{"   (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};\n   m[i].l=1*new Date();\n   for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}\n   k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})\n   (window, document, \"script\", \"https://mc.yandex.ru/metrika/tag.js\", \"ym\");\n\n   ym(92253581, \"init\", {\n        clickmap:true,\n        trackLinks:true,\n        accurateTrackBounce:true,\n        webvisor:true\n   });"}
			</script>
			<script place={"endOfBody"} rawKey={"65ca8f756620802328f143f7"}>
				{"document.getElementById('CloseBlog').addEventListener('click', function() {\n            // Получаем элемент блога\n            var blogContainer = document.getElementById('blogContainer');\n            \n            // Переключаем стиль блога между отображением и скрытием\n            if (blogContainer.style.display === 'none') {\n                blogContainer.style.display = 'block';\n            } else {\n                blogContainer.style.display = 'none';\n            }\n        });"}
			</script>
		</RawHtml>
	</Theme>;
});